/**
 * @file standalone/hero_helper.scss
 *
 * \brief Library file - shared styles for hero helper
 *
 * HERO Helper
 * design debug tool
 * debug div loads via js library - see file ~/aveda_base/js/site/hero_helper.js
 *
 */

@import '../theme-bootstrap';

.hero-helper {
  border: 1px solid $color-gray-lighter;
  background-size: 10% 10%;
  background-image: linear-gradient(to right, $color-gray-lighter 1px, transparent 1px),
    linear-gradient(to bottom, $color-gray-lighter 1px, transparent 1px);
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
}

.hero-debug {
  &.hero-large {
    .hero-large__inner {
      border: 2px solid $color-red;
    }
  }
}
